import { EnvironmentInjector, inject, runInInjectionContext, Injectable } from '@angular/core'
import { isConnectCriterion, isConnectCriterion$ } from '@awork/_shared/functions/criteria/subscription.criteria'
import { Feature } from '@awork/_shared/feature-flags/feature-flag.features'
import { isFeatureEnabledCriterion } from '@awork/_shared/functions/criteria/feature-flag.criteria'
import { UserSettingsService } from '@awork/_shared/services/user-settings-service/user-settings.service'
import { combineLatest, map, Observable, ReplaySubject, shareReplay, startWith, switchMap } from 'rxjs'
import {
  isConnectGiftProjectDeclinedKey,
  isConnectGiftProjectRedeemedKey,
  UserSettingKey
} from '../../user-settings-service/keys'
import { isUserSettingTrue } from '../../user-settings-service/response'
import { UserSetting } from '@awork/_shared/models/user-setting.model'
import { ABTestService } from '../../ab-test-service/ab-test.service'
import { TestSlug } from '../../ab-test-service/ab-test'
import { OnboardingChecklistService } from '../../onboarding-checklist-service/onboarding-checklist.service'
import { ConnectProjectOnboardingService } from '../connect-project-onboarding-service/connect-project-onboarding.service'
import { isDeveloperEnvironmentCriterion } from '@awork/_shared/functions/criteria/environment.criteria'
import { isWorkspaceOlderThanCriterion } from '@awork/_shared/functions/criteria/workspace.criteria'
import { ActionUnion, ofType } from '../functions/of-type'

enum Action {
  RedeemGift = 'redeemGift',
  DeclineGift = 'declineGift'
}

type EventMap = {
  [Action.RedeemGift]: boolean
  [Action.DeclineGift]: boolean
}

/**
 * This service contains all the properties used
 * to run experiments around gifting projects.
 */
@Injectable({ providedIn: 'root' })
export class ConnectGiftService {
  private injector = inject(EnvironmentInjector)
  private userSettingsService = inject(UserSettingsService)
  private abTestService = inject(ABTestService)
  private onboardingChecklistService = inject(OnboardingChecklistService)
  private connectProjectOnboardingService = inject(ConnectProjectOnboardingService)

  private actions = new ReplaySubject<ActionUnion<EventMap>>(1)

  private setIsGiftRedeemed$ = this.actions.pipe(
    ofType<Action.RedeemGift, EventMap>(Action.RedeemGift),
    switchMap(({ payload }) => this.setInUserSettings(payload, isConnectGiftProjectRedeemedKey)),
    startWith(null)
  )

  private setIsGiftDeclined$ = this.actions.pipe(
    ofType<Action.DeclineGift, EventMap>(Action.DeclineGift),
    switchMap(({ payload }) => this.setInUserSettings(payload, isConnectGiftProjectDeclinedKey)),
    startWith(null)
  )

  readonly isChecklistEnabled$ = isConnectCriterion$().pipe(
    map(
      isConnect =>
        isConnect &&
        this.abTestService.isTestGroup(TestSlug.ConnectOnboardingGiftChecklist) &&
        runInInjectionContext(this.injector, isFeatureEnabledCriterion(Feature.ConnectOnboardingChecklistGift))
    )
  )

  readonly isGiftRedeemed$ = this.setIsGiftRedeemed$.pipe(
    switchMap(() => this.getValueInUserSetting(isConnectGiftProjectRedeemedKey).pipe(startWith(false))),
    shareReplay(1)
  )

  readonly isGiftDeclined$ = this.setIsGiftDeclined$.pipe(
    switchMap(() => this.getValueInUserSetting(isConnectGiftProjectDeclinedKey).pipe(startWith(false))),
    shareReplay(1)
  )

  readonly isGiftResolved$ = combineLatest([this.isGiftRedeemed$, this.isGiftDeclined$]).pipe(
    map(([isRedeemed, isDeclined]) => isRedeemed || isDeclined),
    startWith(true),
    shareReplay(1)
  )

  readonly isGiftAvailableForOlderUsers$ = combineLatest([
    this.onboardingChecklistService.isClosedPermanently$,
    this.isGiftResolved$,
    this.connectProjectOnboardingService.hasCreatedProject$,
    this.connectProjectOnboardingService.hasInternalProjects$
  ]).pipe(
    map(([isClosedPermanently, isGiftResolved, hasCreatedProject, hasInternalProjects]) => {
      const workspaceAge = isDeveloperEnvironmentCriterion()
        ? { duration: 5, unit: 'minutes' as const }
        : { duration: 2, unit: 'weeks' as const }

      return (
        isClosedPermanently &&
        !isGiftResolved &&
        !hasCreatedProject &&
        !hasInternalProjects &&
        runInInjectionContext(this.injector, isConnectCriterion) &&
        runInInjectionContext(this.injector, isWorkspaceOlderThanCriterion(workspaceAge.duration, workspaceAge.unit))
      )
    }),
    startWith(false),
    shareReplay(1)
  )

  /**
   * Redeems the project gift
   */
  redeemGift(): void {
    this.actions.next({ type: Action.RedeemGift, payload: true })
  }

  /**
   * Declines the project gift
   */
  declineGift(): void {
    this.actions.next({ type: Action.DeclineGift, payload: true })
  }

  /**
   * Sets the value in the user settings
   * @param {boolean} value
   * @returns {Observable<UserSetting>}
   * @private
   */
  private setInUserSettings(value: boolean, key: UserSettingKey): Observable<UserSetting> {
    return this.userSettingsService.setUserSetting(key, JSON.stringify(value))
  }

  /**
   * Gets the value from the user settings
   * @returns {Observable<boolean>}
   * @private
   */
  private getValueInUserSetting(key: UserSettingKey): Observable<boolean> {
    return this.userSettingsService.getUserSetting(key).pipe(map(isUserSettingTrue))
  }
}
