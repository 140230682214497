import { Criterion } from './types'
import { inject } from '@angular/core'
import { WorkspaceQuery } from '@awork/features/workspace/state/workspace.query'
import { Workspaces } from '@awork/_shared/feature-flags/feature-flag.workspace'
import { add, isPast } from '../date-fns-wrappers'
import { Duration } from 'date-fns'

/**
 * Returns true if the current workspace id is included in the list of allowed ids
 * @param {string[]} allowedWorkspaceIds
 * @returns {boolean}
 */
export const isWorkspaceAllowedCriterion =
  (allowedWorkspaceIds: string[]): Criterion =>
  () => {
    const workspaceQuery = inject(WorkspaceQuery)
    const currentWorkspace = workspaceQuery.getCurrentWorkspace()

    return allowedWorkspaceIds.includes(currentWorkspace?.id)
  }

export const isAworkProductionWorkspaceCriterion = isWorkspaceAllowedCriterion([Workspaces.Production.Awork])
export const isAworkDevelopWorkspaceCriterion = isWorkspaceAllowedCriterion([Workspaces.Develop.Awork])

export const isWorkspaceOlderThanCriterion =
  (number: number, unit: keyof Duration): Criterion =>
  () => {
    const workspaceQuery = inject(WorkspaceQuery)
    const currentWorkspace = workspaceQuery.getCurrentWorkspace()
    const duration: Duration = { [unit]: number }

    return isPast(add(currentWorkspace?.createdOn, duration))
  }
