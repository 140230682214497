import { Project } from '@awork/features/project/models/project.model'
import { Task } from '@awork/features/task/models/task.model'
import { TimeTracking } from '@awork/features/time-tracking/models/time-tracking.model'
import { Company } from '@awork/features/company/models/company.model'
import { User } from '@awork/features/user/models/user.model'
import { TimeTrackingStore } from '@awork/features/time-tracking/state/time-tracking.store'
import { ProjectStore } from '@awork/features/project/state/project.store'
import { TaskStore } from '@awork/features/task/state/task.store'
import { TaskStatusStore } from '@awork/features/task/state/task-status.store'
import { TaskListStore } from '@awork/features/task/state/task-list.store'
import { ChecklistItemStore } from '@awork/features/task/state/checklist-item.store'
import { CompanyStore } from '@awork/features/company/state/company.store'
import { UserStore } from '@awork/features/user/state/user.store'
import { Comment } from '@awork/_shared/models/comment.model'
import { TaskList } from '@awork/features/task/models/task-list.model'
import { TaskStatus } from '@awork/features/task/models/task-status.model'
import { NotificationStore } from '@awork/framework/state/notification.store'
import { Notification } from '@awork/framework/models/notification.model'
import { ChecklistItem } from '@awork/features/task/models/checklist-item'
import { TaskDependencySignal } from '@awork/features/task/models/task-dependency.model'
import { Absence } from '@awork/features/user/models/absence.model'
import { IWatchEntity } from '@awork/framework/services/notification-service/types'
import { Retainer } from '@awork/features/project/models/retainer.model'
import { RetainerBudget } from '@awork/features/project/models/retainer-budget.model'

export type SignalEntityType =
  | 'project'
  | 'task'
  | 'timetracking'
  | 'company'
  | 'user'
  | 'comment'
  | 'watch'
  | 'tasklist'
  | 'taskstatus'
  | 'notification'
  | 'checklistitem'
  | 'taskdependency'
  | 'absence'
  | 'retainer'
  | 'retainerbudget'

export type SignalEntity =
  | Project
  | Task
  | TimeTracking
  | Company
  | User
  | Comment
  | IWatchEntity
  | TaskList
  | TaskStatus
  | Notification
  | ChecklistItem
  | TaskDependencySignal
  | Absence
  | Retainer
  | RetainerBudget

export type SignalEntityStore =
  | ProjectStore
  | TaskStore
  | TimeTrackingStore
  | CompanyStore
  | UserStore
  | TaskListStore
  | TaskStatusStore
  | NotificationStore
  | ChecklistItemStore

export type SignalOperation = 'added' | 'updated' | 'deleted'

interface SignalEntityChange {
  property: string
  old: any
  new: any
}

export interface ISignal {
  entityName: SignalEntityType
  entityId: string
  entity: SignalEntity
  operationType: SignalOperation
  createdBy: string
  changes: SignalEntityChange[]
}

export class Signal implements ISignal {
  entityName: SignalEntityType
  entityId: string
  entity: SignalEntity
  operationType: SignalOperation
  createdBy: string
  changes: SignalEntityChange[]

  constructor(signal: ISignal, currentUser: User) {
    Object.assign(this, signal)
    if (this.entity) {
      switch (this.entityName) {
        case 'project':
          this.entity = new Project(this.entity as Project)
          break
        case 'task':
          this.entity = new Task(this.entity as Task)
          break
        case 'user':
          this.entity = new User(this.entity as User, 'Signal')
          break
        case 'company':
          this.entity = new Company(this.entity as Company)
          break
        case 'timetracking':
          this.entity = new TimeTracking(this.entity as TimeTracking)
          break
        case 'comment':
          this.entity = new Comment(this.entity as Comment)
          break
        case 'watch':
          this.entity = this.entity as IWatchEntity
          break
        case 'tasklist':
          this.entity = new TaskList(this.entity)
          break
        case 'taskstatus':
          this.entity = new TaskStatus(this.entity)
          break
        case 'checklistitem':
          this.entity = new ChecklistItem(this.entity as ChecklistItem)
          break
        case 'notification':
          this.entity = new Notification(this.entity as Notification, currentUser)
          break
        case 'retainer':
          this.entity = new Retainer(this.entity as Retainer)
          break
        case 'retainerbudget':
          this.entity = new RetainerBudget(this.entity as RetainerBudget)
          break
      }
    }
  }
}
