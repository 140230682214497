import { Subject } from 'rxjs'
import { EventEmitter } from '@angular/core'
import { environment } from '@awork/environments/environment'
import isTest from '@awork/_shared/functions/is-test'

type AutoUnsubscribeSettings = {
  blacklist: string[]
  arrayName: string
  secondaryArrayName: string
  includeArrays: boolean
  event: string
}

function isFunction(fn) {
  return typeof fn === 'function'
}

function doUnsubscribe(subscription) {
  if (
    subscription &&
    isFunction(subscription.unsubscribe) &&
    !subscription.closed &&
    !(subscription instanceof EventEmitter || subscription instanceof Subject)
  ) {
    subscription.unsubscribe()
  }
}

function doUnsubscribeIfArray(subscriptionArray) {
  if (Array.isArray(subscriptionArray)) {
    subscriptionArray.forEach(doUnsubscribe)
  }
}

/**
 * Auto-unsubscribes to all registered subscriptions in the class
 * @param {string[]} blacklist - Name of subscriptions that should not be unsubscribed
 * @param {string} arrayName - Subscriptions array name
 * @param {string} secondaryArrayName - Secondary subscriptions array name
 * @param {boolean} includeArrays - To include all arrays in the class
 * @param {string} event - Event when the unsubscription should occur
 * @returns {(constructor: Function) => void}
 * @constructor
 */
export function AutoUnsubscribe({
  blacklist = [],
  arrayName = 'subscriptions',
  secondaryArrayName = 'dataSubscriptions',
  includeArrays = false,
  event = 'ngOnDestroy'
}: Partial<AutoUnsubscribeSettings> = {}) {
  return function (constructor: Function) {
    const original = constructor.prototype[event]

    if (!isFunction(original) && !disableAutoUnsubscribeAot()) {
      console.warn(`${constructor.name} is using @AutoUnsubscribe but does not implement OnDestroy`)
    }

    constructor.prototype[event] = function () {
      if (arrayName && this.hasOwnProperty(arrayName)) {
        doUnsubscribeIfArray(this[arrayName])
      }

      if (secondaryArrayName && this.hasOwnProperty(secondaryArrayName)) {
        doUnsubscribeIfArray(this[secondaryArrayName])
      }

      for (const propName in this) {
        if (this.hasOwnProperty(propName)) {
          if (blacklist.includes(propName)) {
            continue
          }

          const property = this[propName]
          doUnsubscribe(property)

          if (includeArrays) {
            doUnsubscribeIfArray(property)
          }
        }
      }

      if (isFunction(original)) {
        original.apply(this, arguments)
      }
    }

    if (environment === 'local' && !isTest()) {
      const classFactoryName = constructor?.['ɵfac']?.name
      const originalClassName = classFactoryName?.replace('_Factory', '')

      // Fixes override done of the class name when using custom decorators
      Object.defineProperty(constructor, 'name', {
        value: originalClassName,
        writable: false
      })
    }
  }

  function disableAutoUnsubscribeAot() {
    return (window && window['disableAutoUnsubscribeAot']) || window['disableAuthUnsubscribeAot']
  }
}
