import { SubscriptionQuery } from '@awork/_shared/state/subscription.query'
import { Criterion } from './types'
import { inject } from '@angular/core'
import { isAfter, addMonths } from 'date-fns'
import { Plan, PlanStatus } from '@awork/_shared/models/subscription.types'
import { AsyncCriterion } from './async/types'
import { map } from 'rxjs'

/**
 * Returns true if the current subscription is in trial
 * @returns {boolean}
 */
export const isInTrialCriterion: Criterion = () => {
  const subscriptionQuery = inject(SubscriptionQuery)
  const subscription = subscriptionQuery.getSubscription()

  const extendedTrialPeriodMonths = 2
  const isExtendedTrial = isAfter(subscription.trialEnd, addMonths(subscription.trialEnd, extendedTrialPeriodMonths))

  return !subscription.isPlan(Plan.Internal) && !isExtendedTrial && subscription.isPlanStatus(PlanStatus.InTrial)
}

/**
 * Returns true if the current subscription is an awork connect subscription
 * @returns {boolean}
 */
export const isConnectCriterion: Criterion = () => {
  const subscriptionQuery = inject(SubscriptionQuery)
  const subscription = subscriptionQuery.getSubscription()

  return !!subscription?.isPlan(Plan.Connect) || !!subscription?.isBasicConnectInTrial
}

/**
 * Returns true if the current subscription is an awork connect subscription
 * @returns {Observable<boolean>}
 */
export const isConnectCriterion$: AsyncCriterion = () => {
  return inject(SubscriptionQuery)
    .selectSubscription()
    .pipe(map(subscription => !!subscription?.isPlan(Plan.Connect) || !!subscription?.isBasicConnectInTrial))
}
