import { InjectionToken } from '@angular/core'
import { Criterion } from '@awork/_shared/functions/criteria/types'
import { isAnyCriterionTrue } from '@awork/_shared/functions/criteria/composition.criteria'
import { isDevelopCriterion, isLocalhostCriterion } from '@awork/_shared/functions/criteria/environment.criteria'

export enum Feature {
  ExampleFeature = 'exampleFeature',
  NewRichTextEditorLiveEditing = 'newRichTextEditorLiveEditing',
  NpsToastScheduling = 'npsToastScheduling',
  SkipAccountServiceUserCheck = 'skipAccountServiceUserCheck',
  ConnectDiscount = 'connectDiscount',
  ConnectOnboardingChecklistGift = 'connectOnboardingChecklistGift'
}

export type FeatureFlags = { [key in Feature]: Criterion[] }
const featureFlags: FeatureFlags = {
  [Feature.ExampleFeature]: [isDevelopCriterion],
  [Feature.NewRichTextEditorLiveEditing]: [() => true], // @Framework team, please remove
  [Feature.SkipAccountServiceUserCheck]: [() => true], // @Framework team, please remove
  [Feature.NpsToastScheduling]: [isAnyCriterionTrue([isDevelopCriterion, isLocalhostCriterion])],
  [Feature.ConnectDiscount]: [() => true],
  [Feature.ConnectOnboardingChecklistGift]: [() => true]
}

export const FeatureFlagsToken = new InjectionToken<FeatureFlags>('FeatureFlags', {
  providedIn: 'root',
  factory: () => featureFlags
})
