import { EnvironmentInjector, inject, runInInjectionContext, Injectable } from '@angular/core'
import { UserSettingsService } from '@awork/_shared/services/user-settings-service/user-settings.service'
import { ProjectQuery } from '@awork/features/project/state/project.query'
import { UserQuery } from '@awork/features/user/state/user.query'
import { combineLatest, map, Observable, shareReplay, startWith } from 'rxjs'
import {
  atLeastOneProjectHasBeenCreatedKey,
  dashboardOnboardingVideoModalPermanentlyClosedKey,
  isProjectPageBannerPermanentlyClosed,
  onboardingPopupPermanentlyClosedKey,
  UserSettingKey
} from '@awork/_shared/services/user-settings-service/keys'
import { isConnectCriterion } from '@awork/_shared/functions/criteria/subscription.criteria'
import { isUserSettingTrue } from '@awork/_shared/services/user-settings-service/response'
import { isPastDefaultEmailContextualDate } from './timeout'
import { isGermanLanguageCriterion } from '@awork/_shared/functions/criteria/language.criteria'
import { isWorkspaceCreatorCriterion } from '@awork/_shared/functions/criteria/user.criteria'
import { isWorkspaceOlderThanCriterion } from '@awork/_shared/functions/criteria/workspace.criteria'
import { isDeveloperEnvironmentCriterion } from '@awork/_shared/functions/criteria/environment.criteria'

/**
 * This service contains all the properties used
 * to run experiments involving communication on the project creation of
 * connect users.
 */
@Injectable({ providedIn: 'root' })
export class ConnectProjectOnboardingService {
  private userSettingsService = inject(UserSettingsService)
  private projectQuery = inject(ProjectQuery)
  private userQuery = inject(UserQuery)
  private injector = inject(EnvironmentInjector)

  private readonly currentUser = this.userQuery.getCurrentUser()

  // Project creation
  readonly hasInternalProjects$ = this.projectQuery.selectProjectCount(undefined, false).pipe(map(count => count > 0))
  readonly hasCreatedProject$ = this.getBooleanUserSetting(atLeastOneProjectHasBeenCreatedKey)

  // Permanently closed
  readonly isOnboardingPopupClosedPermanently$ = this.getBooleanUserSetting(onboardingPopupPermanentlyClosedKey)
  readonly isBannerPermanentlyClosed$ = this.getBooleanUserSetting(isProjectPageBannerPermanentlyClosed)
  readonly isOnboardingVideoModalPermanentlyClosed$ = this.getBooleanUserSetting(
    dashboardOnboardingVideoModalPermanentlyClosedKey
  )

  /**
   * An Observable that checks if the user is in the connect projects onboarding
   */
  readonly isConnectProjectsOnboarding$ = combineLatest([this.hasCreatedProject$, this.hasInternalProjects$]).pipe(
    map(
      ([hasCreatedProject, hasInternalProjects]) =>
        runInInjectionContext(this.injector, isConnectCriterion) && !hasCreatedProject && !hasInternalProjects
    ),
    shareReplay(1)
  )

  /**
   * An Observable that checks if the user is in the connect projects onboarding
   * and past two weeks after the user's creation date
   */
  readonly isConnectProjectsOnboardingWithinDefaultTimeframe$ = this.isConnectProjectsOnboarding$.pipe(
    map(
      isConnectProjectsOnboarding =>
        isConnectProjectsOnboarding &&
        isPastDefaultEmailContextualDate({ email: this.currentUser.email, referenceDate: this.currentUser.createdOn })
    ),
    shareReplay(1)
  )

  /**
   * An Observable that checks if the user is in the connect projects onboarding
   * and it is the workspace creator
   * and has German as selected language
   * and its workspace was created more than 30 days ago
   */
  readonly isDashboardVideoOnboardingModalVisible$ = this.isConnectProjectsOnboarding$.pipe(
    map(isConnectProjectsOnboarding => {
      if (!isConnectProjectsOnboarding) {
        return false
      }

      const workspaceAge = isDeveloperEnvironmentCriterion()
        ? { duration: 10, unit: 'minutes' as const }
        : { duration: 30, unit: 'days' as const }

      return (
        runInInjectionContext(this.injector, isWorkspaceCreatorCriterion) &&
        runInInjectionContext(this.injector, isGermanLanguageCriterion) &&
        runInInjectionContext(this.injector, isWorkspaceOlderThanCriterion(workspaceAge.duration, workspaceAge.unit))
      )
    }),
    shareReplay(1)
  )

  /**
   * Creates an Observable that checks for the user setting
   * and returns a boolean based on the setting value
   * @param {UserSettingKey} key
   * @returns {Observable<boolean>}
   */
  private getBooleanUserSetting(key: UserSettingKey): Observable<boolean> {
    return this.userSettingsService.getUserSetting(key).pipe(map(isUserSettingTrue), startWith(true))
  }
}
