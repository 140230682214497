import { from, map, Observable, of } from 'rxjs'
import { QueryParams } from '@awork/_shared/services/api-client/ApiClient'

export const MAX_FILTER_BY_LENGTH = 1800

/**
 * Compress a string using Gzip and return the compressed data as a Base64 string
 * @param {string} input
 * @returns {Promise<string>}
 */
export async function compressStringWithGzip(input: string): Promise<string> {
  const encoder = new TextEncoder()

  // Convert the input string to a Uint8Array
  const inputArray = encoder.encode(input)

  // Create a Gzip CompressionStream
  const compressionStream = new CompressionStream('gzip')
  const writableStream = compressionStream.writable.getWriter()
  writableStream.write(inputArray)
  writableStream.close()

  // Helper function to collect all chunks
  const collectChunks = async (
    reader: ReadableStreamDefaultReader<Uint8Array>,
    chunks: Uint8Array[] = []
  ): Promise<Uint8Array[]> => {
    const result = await reader.read()
    if (result.done) {
      return chunks
    }
    chunks.push(result.value)
    return collectChunks(reader, chunks) // Recurse until done
  }

  // Collect all chunks
  const reader = compressionStream.readable.getReader()
  const compressedData = await collectChunks(reader)

  // Combine chunks into a single Uint8Array
  const totalLength = compressedData.reduce((sum, chunk) => sum + chunk.length, 0)
  const compressedArray = new Uint8Array(totalLength)
  let offset = 0
  compressedData.forEach(chunk => {
    compressedArray.set(chunk, offset)
    offset += chunk.length
  })

  // Convert the compressed data to a Base64 string
  return btoa(String.fromCharCode(...compressedArray))
}

/**
 * Decompress a Base64 string that was compressed using Gzip and return the original string
 * @param {string} base64Input
 * @returns {Promise<string>}
 */
export async function decompressStringWithGzip(base64Input: string): Promise<string> {
  const decoder = new TextDecoder()

  // Convert the Base64 string to a Uint8Array
  const compressedArray = Uint8Array.from(atob(base64Input), char => char.charCodeAt(0))

  // Create a Gzip DecompressionStream
  const decompressionStream = new DecompressionStream('gzip')
  const writableStream = decompressionStream.writable.getWriter()
  writableStream.write(compressedArray)
  writableStream.close()

  // Helper function to collect all chunks
  const collectChunks = async (
    reader: ReadableStreamDefaultReader<Uint8Array>,
    chunks: Uint8Array[] = []
  ): Promise<Uint8Array[]> => {
    const result = await reader.read()
    if (result.done) {
      return chunks
    }
    chunks.push(result.value)
    return collectChunks(reader, chunks) // Recurse until done
  }

  // Collect all chunks
  const reader = decompressionStream.readable.getReader()
  const decompressedData = await collectChunks(reader)

  // Combine chunks into a single Uint8Array
  const totalLength = decompressedData.reduce((sum, chunk) => sum + chunk.length, 0)
  const decompressedArray = new Uint8Array(totalLength)
  let offset = 0
  decompressedData.forEach(chunk => {
    decompressedArray.set(chunk, offset)
    offset += chunk.length
  })

  // Decode the decompressed data back to a string
  return decoder.decode(decompressedArray)
}

/**
 * Compresses the filterBy query parameter if it is too large.
 * It removes the filterBy parameter and adds a filterByC parameter with the compressed data.
 * @param {QueryParams} queryParams
 * @returns {Observable<QueryParams>}
 */
export function compressFilterBy(queryParams: QueryParams): Observable<QueryParams> {
  if (queryParams.filterBy?.length > MAX_FILTER_BY_LENGTH) {
    return from(compressStringWithGzip(queryParams.filterBy)).pipe(
      map(compressedFilterBy => {
        queryParams.filterByC = compressedFilterBy
        delete queryParams.filterBy
        return queryParams
      })
    )
  }

  return of(queryParams)
}
