import { getState, patchState, signalStore, withState, WritableStateSource } from '@ngrx/signals'
import { effect, inject, Signal, signal } from '@angular/core'
import { distinctUntilChanged, Observable } from 'rxjs'
import { isFunction } from '@awork/core/state/signal-store/helpers'
import { SignalStoreService } from '@awork/core/state/signal-store/signalStore.service'
import { updateState, withDevtools } from '@angular-architects/ngrx-toolkit'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'
import { withStateStorage } from '@awork/core/state/signal-store/withStorage'
import { withNoop } from '@awork/core/state/signal-store/withNoop'
import { SignalStoreHistory } from '@awork/core/state/signal-store/signalStoreHistory'
import { toObservable } from '@angular/core/rxjs-interop'

export type StoreState<State extends object> = WritableStateSource<State> & { [K in keyof State]: Signal<State[K]> }

export interface StoreOptions<State> {
  name: string
  initialState: State
  resettable?: boolean
  persistDelay?: number
  excludePersistProps?: (keyof State)[]
}

/**
 * Signal store for state.
 * Provides functions to update the state.
 *
 * ### Usage example
 * ```ts
 * @Injectable({ providedIn: 'root' })
 * export class AppStore extends SignalStore<AppState> {
 *   constructor() {
 *     super({
 *       resettable: true,
 *       name: 'user',
 *       initialState: createInitialState(),
 *     })
 *   }
 * }
 * ```
 */
export class SignalStore<State extends object> {
  private store: StoreState<State>

  private storeOptions: StoreOptions<State>

  protected isLoading = signal(true)
  private error = signal(null)

  private readonly stateSubject: BehaviorSubject<State>
  isLoading$: Observable<boolean>
  error$: Observable<Error>

  readonly history: SignalStoreHistory<State>

  private signalStoreService = inject(SignalStoreService<State>)

  private readonly DEFAULT_STORE_OPTIONS: Partial<StoreOptions<State>> = {
    resettable: true
  }

  private integrateDevtools: boolean

  constructor(storeOptions?: StoreOptions<State>) {
    this.applyStoreOptions(storeOptions)

    this.stateSubject = new BehaviorSubject<State>(this.storeOptions.initialState)

    this.createStore()

    this.initObservables()

    this.signalStoreService.registerStore(this.storeOptions.name, this)
    this.history = new SignalStoreHistory(this)
  }

  /**
   * Applies the store options, merging them with the default store options
   * @param {StoreOptions<State>} storeOptions
   */
  private applyStoreOptions(storeOptions: StoreOptions<State>): void {
    this.storeOptions = {
      ...this.DEFAULT_STORE_OPTIONS,
      ...storeOptions
    }
  }

  /**
   * Creates the signal store
   */
  private createStore(): void {
    this.integrateDevtools = localStorage.getItem('awReduxDevtools') === 'true'

    const StoreFactory = signalStore(
      { protectedState: false },
      this.integrateDevtools ? withDevtools(this.storeOptions.name) : withNoop(),
      withState(this.storeOptions.initialState),
      withStateStorage(this, {
        key: this.storeOptions.name,
        persistDelay: this.storeOptions.persistDelay,
        excludeProps: this.storeOptions.excludePersistProps
      })
    )

    this.store = new StoreFactory() as StoreState<State>
  }

  /**
   * Initializes the observables used for RxJS interop
   */
  private initObservables(): void {
    effect(() => {
      const state = getState(this.store)
      this.stateSubject.next(state)
    })

    this.isLoading$ = toObservable(this.isLoading)
    this.error$ = toObservable(this.error)
  }

  /**
   * Gets the store
   * @returns {StoreState<T>}
   */
  getState(): StoreState<State> {
    return this.store
  }

  /**
   * Gets the store options
   * @returns {StoreOptions<State>}
   */
  getStoreOptions(): StoreOptions<State> {
    return this.storeOptions
  }

  /**
   * Hook that is called before updating a state in the store
   * @param {State} prevState
   * @param {Partial<State>} nextState
   * @returns {Partial<State>}
   * @internal
   */
  preUpdateState(prevState: State, nextState: Partial<State>): Partial<State> {
    return nextState
  }

  /**
   * Applies the preUpdateState hook to the state before updating it in the store
   * @param {State} prevState
   * @param {Partial<State> | ((state: State) => Partial<State>)} newState
   * @returns {Partial<State>}
   */
  private applyPreUpdateState(
    prevState: State,
    newState: Partial<State> | ((state: State) => Partial<State>)
  ): Partial<State> {
    const nextState = isFunction(newState) ? newState(prevState) : newState
    const mergedNextState = { ...prevState, ...nextState }
    return this.preUpdateState(prevState, mergedNextState)
  }

  /**
   * Sets the loading state
   * @param {boolean} isLoading
   */
  setLoading(isLoading: boolean): void {
    this.isLoading.set(isLoading)
  }

  /**
   * Gets the loading state
   * @returns {Signal<boolean>}
   */
  getIsLoading(): Signal<boolean> {
    return this.isLoading
  }

  /**
   * Sets the error state
   * @param {Error} error
   */
  setError(error: Error): void {
    this.error.set(error)
  }

  /**
   * Gets the error state
   * @returns {Signal<Error>}
   */
  getError(): Signal<Error> {
    return this.error
  }

  get state$(): Observable<State> {
    return this.stateSubject.pipe(distinctUntilChanged())
  }

  /**
   * Updates the state in the store
   *
   * @example
   * update({ value: 'newValue' })
   * update(state => ({ value: state.value + 'newValue' }))
   */
  update(newState: Partial<State>, action?: string): void
  update(newStateFn: (state: State) => Partial<State>, action?: string): void
  update(
    newStateOrFn: Partial<State> | ((state: State) => Partial<State>),
    action = this.storeOptions.name + ': update'
  ): void {
    this.integrateDevtools
      ? updateState(this.store, action, state => this.applyPreUpdateState(state, newStateOrFn))
      : patchState(this.store, state => this.applyPreUpdateState(state, newStateOrFn))

    if (this.isLoading()) {
      this.setLoading(false)
    }
  }

  /**
   * Resets the store by setting the state to the initial state
   * @param {string} action
   */
  reset(action = this.storeOptions.name + ': reset'): void {
    this.integrateDevtools
      ? updateState(this.store, action, this.storeOptions.initialState)
      : patchState(this.store, this.storeOptions.initialState)

    this.isLoading.set(true)
    this.error.set(null)
  }
}
