import { Criterion } from './types'
import { inject } from '@angular/core'
import { Feature } from '@awork/_shared/feature-flags/feature-flag.features'
import { FeatureFlagService } from '@awork/_shared/feature-flags/feature-flag.service'

/**
 * Returns true if the feature is enabled
 * @returns {boolean}
 */
export const isFeatureEnabledCriterion =
  (feature: Feature): Criterion =>
  () => {
    const featureFlagService = inject(FeatureFlagService)
    const isFeatureEnabled = featureFlagService.isFeatureEnabled(feature)

    return isFeatureEnabled
  }
