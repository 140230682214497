import { PermissionsQuery } from '@awork/features/workspace/state/permissions.query'
import { Criterion } from './types'
import { inject } from '@angular/core'
import { ProjectQuery } from '@awork/features/project/state/project.query'
import { PermissionsService } from '@awork/features/workspace/services/permission-service/permissions.service'
import { AccessLevels, Features } from '@awork/features/workspace/models/permissions.model'

/**
 * Returns true if the current user is an admin
 * @returns {boolean}
 */
export const isAdminCriterion: Criterion = () => {
  const permissionsQuery = inject(PermissionsQuery)
  const userPermissions = permissionsQuery.getUserPermissions()

  return !!userPermissions?.isAdmin
}

export const isAllowedToCreateTasksForExternalProject: Criterion = () => {
  const projectQuery = inject(ProjectQuery)
  const permissionsService = inject(PermissionsService)

  const allProjects = projectQuery.getAll() ?? []
  const project = allProjects.find(project => {
    if (!project.isExternal) {
      return false
    }

    return permissionsService.isAllowed(Features.ProjectPlanning, AccessLevels.Manage, false, project)
  })

  return !!project
}
