import { catchError, forkJoin, map, Observable, of } from 'rxjs'
import { effect, Injectable } from '@angular/core'
import { apiEndpoint, app } from '@awork/environments/environment'
import { Role, UserRole } from '@awork/features/workspace/models/role.model'
import { ApiClient, QueryParams } from '@awork/_shared/services/api-client/ApiClient'
import {
  AccessLevels,
  Feature,
  Features,
  Permissions,
  UserPermissions
} from '@awork/features/workspace/models/permissions.model'
import { Router } from '@angular/router'
import { Project } from '@awork/features/project/models/project.model'
import { ProjectMember } from '@awork/features/project/models/project-member.model'
import { ProjectRole } from '@awork/features/project/models/project-role.model'
import { TitleCasePipe } from '@angular/common'
import { PermissionsStore } from '@awork/features/workspace/state/permissions.store'
import { PermissionsQuery } from '@awork/features/workspace/state/permissions.query'
import { WorkspaceQuery } from '@awork/features/workspace/state/workspace.query'
import { AppQuery } from '@awork/core/state/app.query'
import { UserQuery, UserRoleMapping } from '@awork/features/user/state/user.query'
import { HttpParams } from '@angular/common/http'
import { WorkspaceFeature } from '@awork/features/workspace/models/workspace.model'
import { SubscriptionQuery } from '@awork/_shared/state/subscription.query'
import { TeamQuery } from '@awork/features/team/state/team.query'
import { IDeleteRoleBody } from '@awork/features/workspace/services/permission-service/types'
import { Plan } from '@awork/_shared/models/subscription.types'

@Injectable({ providedIn: 'root' })
export class PermissionsService {
  private url: string
  private roles: Role[] = []

  permissionsFetched: boolean

  constructor(
    private apiClient: ApiClient,
    private permissionsStore: PermissionsStore,
    private permissionsQuery: PermissionsQuery,
    private router: Router,
    private appQuery: AppQuery,
    private userQuery: UserQuery,
    private subscriptionQuery: SubscriptionQuery,
    private workspaceQuery: WorkspaceQuery,
    private teamQuery: TeamQuery
  ) {
    this.url = `${apiEndpoint}/`
    this.permissionsFetched = false

    // To fetch the permissions again after logout and login
    effect(() => {
      const isLoggedIn = this.appQuery.query('isLoggedIn')

      if (!isLoggedIn()) {
        this.permissionsFetched = false
      }
    })
  }

  /**
   * Determines if the current user is allowed to make a certain action.
   * This method checks for admin permissions only.
   * @param redirect
   */
  public isAllowedAdmin(redirect = false): boolean {
    const userPermissions = this.permissionsQuery.getUserPermissions()
    if (userPermissions) {
      if (userPermissions.isAdmin) {
        return true
      } else {
        return this.notAllowedAction(redirect)
      }
    }
    return false
  }

  /**
   * Determines if the current user is allowed to use a feature
   * @param {Features | Features[]} feature - feature requested
   * @param {AccessLevels} accessLevel - access level needed
   * @param {boolean} redirect - True to redirect to 'not allowed' page
   * @param {Project} project - The project to check project role permissions
   * @returns {boolean} - True if is allowed, false otherwise
   */
  public isAllowed(
    feature: Features | Features[],
    accessLevel: AccessLevels,
    redirect = false,
    project?: Project
  ): boolean {
    const userPermissions = this.permissionsQuery.getUserPermissions()
    let features: Features[]

    if (!(feature instanceof Array)) {
      features = [feature]
    } else {
      features = feature
    }

    if (!userPermissions) {
      this.notAllowedAction(redirect)
    }

    if (project?.isExternal) {
      return this.checkProjectRolePermissions(userPermissions, features, accessLevel, redirect, project)
    }

    // If is an admin or 'None' is required or no features were specified, is allowed
    if (
      userPermissions.isAdmin ||
      accessLevel === undefined ||
      accessLevel === null ||
      accessLevel === AccessLevels.None ||
      !features ||
      features.length === 0
    ) {
      return true
    }

    // Check global permissions
    if (userPermissions.permissions?.length) {
      // If the user has the needed global permission, allow it,
      // otherwise proceed to the project role permissions check (if applies)
      if (this.checkPermissions(userPermissions.permissions, features, accessLevel, redirect)) {
        return true
      }
    }

    // Check for project ownership
    if (project) {
      const currentUser = this.userQuery.getCurrentUser()
      if (currentUser && project.createdBy === currentUser.id) {
        return true
      }

      return this.checkProjectRolePermissions(userPermissions, features, accessLevel, redirect, project)
    }

    return this.notAllowedAction(redirect)
  }

  /**
   * Determines if the current user is allowed to use a feature according to the project role permissions
   * @param {UserPermissions} userPermissions
   * @param {Features[]} features
   * @param {AccessLevels} accessLevel
   * @param {boolean} redirect
   * @param {Project} project
   * @returns {boolean} - True if is allowed, notAllowedAction otherwise
   */
  checkProjectRolePermissions(
    userPermissions: UserPermissions,
    features: Features[],
    accessLevel: AccessLevels,
    redirect = false,
    project: Project
  ): boolean {
    if (userPermissions.projectRolesPermissions?.length && project.members?.length) {
      // Check if the current user is member of the project
      const userMember = this.getCurrentUserProjectMember(project, userPermissions)

      if (userMember) {
        // Look for user's permissions for the role in the project
        const projectRolePermissions = userPermissions.projectRolesPermissions.find(
          projectRolePermission => projectRolePermission.projectRoleId === userMember.projectRoleId
        )

        // If the user has permissions for the role, check if has the required permissions
        if (projectRolePermissions) {
          return this.checkPermissions(projectRolePermissions.permissions, features, accessLevel, redirect)
        }
      }
    }

    return this.notAllowedAction(redirect)
  }

  /**
   * Determines if the current user has Own Team permissions for a given project
   * It is important to use this check in combination with isAllowed of global project features (Master/Planning)
   * @param {Project} project
   * @returns {boolean} - True if is allowed, false otherwise
   */
  isAllowProjectOwnTeamPermissions(project: Project): boolean {
    const userPermissions = this.permissionsQuery.getUserPermissions()

    if (userPermissions.isAdmin) {
      return true
    }

    const hasOwnTeamsPermission = userPermissions.permissions.find(
      permission => permission.feature === Feature.getFeatureStringByEnum(Features.ProjectOwnTeam)
    )

    if (!hasOwnTeamsPermission) {
      return true
    }

    if (!project) {
      return false
    }

    return this.hasProjectOwnTeamPermissions(project, userPermissions)
  }

  /**
   * Checks if user belongs to the project (via project member or team)
   * @param {Project} project
   * @param {UserPermissions} userPermissions
   * @private
   * @return {boolean}
   */
  private hasProjectOwnTeamPermissions(project: Project, userPermissions: UserPermissions): boolean {
    const projectMember = this.getCurrentUserProjectMember(project, userPermissions)

    if (projectMember) {
      return true
    }

    const ownTeams = this.teamQuery.getUserTeams(userPermissions.userId)
    const ownTeamsIds = ownTeams?.map(team => team.id) || []

    // Returns whether the user belong to any team of the given project
    return project.teams?.some(team => ownTeamsIds.includes(team.id))
  }

  /**
   * Returns a project member if the user is a member of the project
   * @param {Project} project
   * @param {UserPermissions} userPermissions
   * @private
   * @return {ProjectMember}
   */
  private getCurrentUserProjectMember(project: Project, userPermissions: UserPermissions): ProjectMember {
    return project.members?.find(member => member.userId === userPermissions.userId)
  }

  /**
   * Check if the user has the permissions needed for certain features
   * @param {Permissions[]} userPermissions
   * @param {Features[]} features
   * @param {AccessLevels} accessLevel
   * @param {boolean} redirect
   * @return {boolean}
   */
  private checkPermissions(
    userPermissions: Permissions[],
    features: Features[],
    accessLevel: AccessLevels,
    redirect: boolean
  ): boolean {
    // search for these features in the user's permissions
    const permissions = userPermissions.filter(permission => {
      // Filter the features array to only include features that the user has permissions for
      const featuresFiltered = Feature.features.filter(permissionFeature => features.includes(permissionFeature.id))
      // Find a feature that matches the user's permission
      const featureFound = featuresFiltered.find(feature => feature.value === permission.feature)

      // If a matching feature is found, the user is allowed
      return !!featureFound
    })

    if (permissions) {
      const allowed = permissions.some(permission => {
        if (accessLevel === AccessLevels.Manage) {
          // if 'Manage' is required, only 'Manage' is accepted
          return permission.accessLevels.includes(Feature.accessLevels[AccessLevels.Manage])
        } else if (accessLevel === AccessLevels.Read) {
          // if 'Read' is required, 'Manage' and 'Read' are accepted
          return (
            permission.accessLevels.includes(Feature.accessLevels[AccessLevels.Manage]) ||
            permission.accessLevels.includes(Feature.accessLevels[AccessLevels.Read])
          )
        }

        return this.notAllowedAction(redirect)
      })

      if (allowed) {
        return allowed
      }
    }

    return false
  }

  /**
   * Checks if the user is allowed to see the specific menuitem
   * @return {boolean}
   */
  public isAllowedToSeeMenuItem(menuItemId: string, redirect: boolean): boolean {
    const userPermissions = this.permissionsQuery.getUserPermissions()

    const allowedToSee = !userPermissions.deactivatedMenuItems.includes(menuItemId)

    if (!allowedToSee) {
      return this.notAllowedAction(redirect)
    } else {
      return true
    }
  }

  /**
   * Executes the action required when the user is not allowed for certain feature
   * @param redirect - True to redirect to 'not allowed' page
   * @return {boolean}
   */
  private notAllowedAction(redirect): boolean {
    if (redirect && app === 'web') {
      this.router.navigate(['/not-allowed'], { skipLocationChange: true })
    }
    return false
  }

  /**
   * Makes an API call to request the user's permissions
   * @returns {Observable<UserPermissions>}
   */
  public fetchPermissions(): Observable<UserPermissions> {
    this.permissionsFetched = true

    return this.apiClient.get<UserPermissions>(`${this.url}me/permissions`).pipe(
      map(permissions => {
        this.permissionsStore.update(permissions.userPermission)
        return new UserPermissions(permissions.userPermission)
      }),
      catchError(() => {
        this.permissionsFetched = false
        return of(null)
      })
    )
  }

  /**
   * Checks whether current workspace has any guest roles
   * @returns {Observable<boolean[]>}
   */
  public hasGuestRole(): Observable<boolean> {
    return this.getRoles().pipe(
      map(roles =>
        roles.some(role => {
          const mappedRole = new Role(role)

          return mappedRole.isGuestRole
        })
      )
    )
  }

  /**
   * Gets the roles of the current workspace
   * @returns {Observable<Role[]>}
   */
  public getRoles(): Observable<Role[]> {
    return this.apiClient.get<Role[]>(`${this.url}roles`).pipe(
      map(roles => {
        this.roles = roles.map(role => new Role(role))
        return this.roles
      })
    )
  }

  public getRole(nameOrId: string): Observable<Role> {
    return this.apiClient.get<Role>(`${this.url}roles/${nameOrId}`).pipe(map(role => new Role(role)))
  }

  /**
   * Gets all the user ids with their role id
   * @returns {UserRole[]} users mapped to their specific role id
   */
  public getUsersRoles(): Observable<UserRole[]> {
    return this.apiClient.get<UserRole[]>(`${this.url}roles/users`)
  }

  /**
   * Gets a role by user id
   * @param userId The id of the user
   * @returns The role of the provided user
   */
  public getRoleByUserId(userId: string): Observable<Role> {
    return this.apiClient.get<Role>(`${this.url}roles/byuserid/${userId}`).pipe(map(role => new Role(role)))
  }

  /**
   * Gets the default role of the workspace (first non admin role, if none, the admin role)
   * @returns {Role | undefined }
   */
  public getDefaultRole(): Role | undefined {
    let defaultRole: Role
    if (this.roles) {
      defaultRole = this.roles.find(role => !role.isAdminRole && !role.isGuestRole)

      if (!defaultRole) {
        defaultRole = this.roles.find(role => role.isAdminRole)
      }
    }
    return defaultRole
  }

  /**
   * Gets the admin role of the workspace
   * @returns {Role}
   */
  public getAdminRole(): Role {
    let defaultRole: Role
    if (this.roles) {
      defaultRole = this.roles.find(role => role.isAdminRole)
    }
    return defaultRole
  }

  /**
   * Post/Put the role to the API
   * @param {Role} role - The role to be sent to the backend
   * @returns {Observable<Role>}
   */
  public sendRole(role: Role): Observable<Role> {
    if (role.id) {
      return this.apiClient.put<Role>(`${this.url}roles/${role.id}`, role)
    } else {
      return this.apiClient.post<Role>(`${this.url}roles`, role)
    }
  }

  /**
   * Gets the role's permissions
   * @param {string} roleId
   * @returns {Observable<Permissions[]>}
   */
  public getRolePermissions(roleId: string): Observable<Permissions[]> {
    return this.apiClient.get<Permissions[]>(`${this.url}roles/${roleId}/permissions`)
  }

  /**
   * Post/Put/Delete the role's permissions to the API
   * @param {Role} role
   * @param {Permissions} permission
   * @returns {Observable<Permissions>}
   */
  public sendRolePermission(role: Role, permission: Permissions): Observable<Permissions> {
    if (permission.accessLevels.includes('none')) {
      permission.accessLevels = []
    }
    return this.apiClient.post<Permissions>(`${this.url}roles/${role.id}/permissions`, permission)
  }

  /**
   * Post/Put/Delete the role's permissions to the API
   * @param {Role} role
   * @param {Permissions[]} permissions
   * @returns {Observable<Permissions>}
   */
  public sendRolePermissions(role: Role, permissions: Permissions[]): Observable<Permissions[]> {
    const permissionCalls = permissions.map(p => this.sendRolePermission(role, p))
    return forkJoin(permissionCalls)
  }

  /**
   * Gets the project role's permissions
   * @param {string} roleId
   * @returns {Observable<Permissions[]>}
   */
  public getProjectRolePermissions(roleId: string): Observable<Permissions[]> {
    return this.apiClient.get<Permissions[]>(`${this.url}projectroles/${roleId}/permissions`)
  }

  /**
   * Post/Put/Delete the project role's permissions to the API
   * @param {ProjectRole} projectRole
   * @param {Permissions} permission
   * @returns {Observable<Permissions>}
   */
  public sendProjectRolePermission(projectRole: ProjectRole, permission: Permissions): Observable<Permissions> {
    if (permission.accessLevels.includes('none')) {
      permission.accessLevels = []
    }
    return this.apiClient.post<Permissions>(`${this.url}projectroles/${projectRole.id}/permissions`, permission)
  }

  /**
   * Gets the role's page permissions
   * @param {string} roleId
   * @returns {Observable<string[]>}
   */
  public getRolePagePermissions(roleId: string): Observable<string[]> {
    return this.apiClient.get<string[]>(`${this.url}roles/${roleId}/deactivatedmenuitems`)
  }

  /**
   * Put the role's page permissions to the API
   * @param {Role} role
   * @param {string[]} pagePermissions
   * @returns {Observable<string>}
   */
  public sendRolePagePermission(role: Role, pagePermissions: string[]): Observable<string> {
    return this.apiClient.post<string>(`${this.url}roles/${role.id}/deactivatedmenuitems`, pagePermissions)
  }

  /**
   * Method to obatin the users specific to a roleId
   * @param {Role} role - The role
   * @returns {Observable<User[]>} - The list of users specific to that role
   */
  getUsersSpecificToRole(role: Role, queryParams: QueryParams = {}): Observable<UserRoleMapping[]> {
    const queryParamsWithDefaults = this.setDefaultQueryParams(queryParams)

    const params: HttpParams = ApiClient.getQueryParams(queryParamsWithDefaults)

    return this.apiClient
      .getAll<{ isDeactivated: boolean; userId: string }[]>(`${this.url}roles/${role.id}/users`, { params })
      .pipe(
        map(userRole => {
          return userRole.map(user => ({
            isDeactivated: user.isDeactivated,
            userId: user.userId,
            roleId: role.id,
            name: role.name
          }))
        })
      )
  }

  /**
   * API call to move user to one role to another role
   * @param {string} userId - The id of the userId
   * @param {string} oldRoleId - The old role id
   * @param {string} newRoleId - The new role id
   * @returns {Observable<string>}
   */
  public moveUser(userId: string, oldRoleId: string, newRoleId: string): Observable<string> {
    return this.apiClient.post<string>(`${this.url}roles/moveuser`, {
      userId,
      fromRoleId: oldRoleId,
      toRoleId: newRoleId
    })
  }

  /**
   * Makes an API call to delete the role and migrate the users, if any to a different role
   * @param {string} roleId - Id of the role to be deleted
   * @param {IDeleteRoleBody} body
   * @returns {Observable<string>}
   */
  public deleteRole(roleId: string, body: IDeleteRoleBody): Observable<string> {
    return this.apiClient.post(`${this.url}roles/${roleId}/delete`, body)
  }

  /**
   * Makes an API call to get all the existing features
   * @returns {Observable<Feature[]>}
   */
  public getFeatures(): Observable<Feature[]> {
    return this.apiClient.get<string[]>(`${this.url}permissions/features`).pipe(
      map(features => {
        let featureCount = Feature.features.length + 1

        return features.map(feature => {
          const descriptiveFeature = Feature.features.find(f => f.value === feature)

          return descriptiveFeature
            ? descriptiveFeature
            : {
                id: ++featureCount,
                value: feature,
                name: new TitleCasePipe().transform(feature.replace(/-/g, ' ')),
                description: ''
              }
        })
      })
    )
  }

  /**
   * Filters the items according to the user permissions
   * @param {string} pageId
   * @param {WorkspaceFeature} feature
   * @returns {boolean}
   * @private
   */
  // eslint-disable-next-line complexity
  isPageVisible(pageId: string, feature?: WorkspaceFeature): boolean {
    let isPageVisible: boolean

    const userPermissions = this.permissionsQuery.getUserPermissions()
    const userRolePermissions = userPermissions?.permissions?.map(p => new Permissions(p)) || []
    const isAdmin = userPermissions?.isAdmin

    const isInMSTeams = this.appQuery.getIsInMSTeams()
    const isWorkspaceManager = this.isAllowed(Features.WorkspaceManager, AccessLevels.Manage)

    const currentSubscription = this.subscriptionQuery.getSubscription()
    const currentWorkspace = this.workspaceQuery.getCurrentWorkspace()

    if (currentWorkspace && !currentWorkspace.isFeatureEnabled(feature)) {
      return false
    }

    if (userPermissions.isAdmin) {
      return true
    }

    if (userPermissions?.deactivatedMenuItems) {
      const isPageEnabled = !PermissionsService.isPagePermissionDisabled(pageId, userRolePermissions)

      isPageVisible = userPermissions.isAllowedToSeeMenuItem(pageId) && isPageEnabled
    }

    if (isInMSTeams && pageId === 'settings-integrations') {
      isPageVisible = false
    }

    if (!isWorkspaceManager && ['settings-integrations', 'settings-workspace', 'settings-teams'].includes(pageId)) {
      isPageVisible = false
    }

    if (
      !isAdmin &&
      ['settings-subscription', 'settings-invite-users', 'settings-workspace-permissions', 'settings-connect'].includes(
        pageId
      )
    ) {
      isPageVisible = false
    }

    const isPlan = currentSubscription?.isPlan(Plan.Internal)
    if (isAdmin && pageId === 'settings-subscription' && isPlan) {
      isPageVisible = false
    }

    if (pageId === 'settings-categories' && this.shouldHideCategories(isPageVisible, userPermissions)) {
      isPageVisible = false
    }

    if (pageId === 'team-planner' && !this.hasUserPlannerPermissions(userPermissions)) {
      isPageVisible = false
    }

    if (pageId === 'settings-templates' && userPermissions.isGuest) {
      isPageVisible = false
    }

    return isPageVisible
  }

  /**
   * Checks if the page permission (menu item visibility) should be disabled,
   * by checking other dependent permissions
   * @param {string} pageId
   * @param {Permissions[]} permissions
   * @returns {boolean}
   */
  static isPagePermissionDisabled(pageId: string, permissions: Permissions[]): boolean {
    let permission

    switch (pageId) {
      case 'settings-workspace':
      case 'settings-teams':
      case 'settings-integrations':
      case 'settings-absence':
      case 'settings-connect':
        const workspaceFeature = Feature.getFeatureStringByEnum(Features.WorkspaceManager)

        permission = permissions.find(p => p.feature === workspaceFeature)?.hasAnyAccessLevel(['manage'])

        return !permission
      case 'users-all':
        const userFeature = Feature.getFeatureStringByEnum(Features.UserMaster)
        permission = permissions.find(p => p.feature === userFeature)?.hasAnyAccessLevel(['manage', 'read'])

        return !permission
      case 'companies-all':
        const companyFeature = Feature.getFeatureStringByEnum(Features.CompanyMaster)
        permission = permissions.find(p => p.feature === companyFeature)?.hasAnyAccessLevel(['manage', 'read'])

        return !permission
      case 'settings-categories':
        const taskFeature = Feature.getFeatureStringByEnum(Features.TaskManage)
        const projectFeature = Feature.getFeatureStringByEnum(Features.ProjectManage)

        permission = permissions.find(p => p.feature === taskFeature)?.hasAnyAccessLevel(['manage', 'read'])

        const projectPermission = permissions
          .find(p => p.feature === projectFeature)
          ?.hasAnyAccessLevel(['manage', 'read'])

        return !(permission || projectPermission)
      case 'time-tracking-controlling':
        const projectTTFeature = Feature.getFeatureStringByEnum(Features.ProjectTimetracking)
        const userTTFeature = Feature.getFeatureStringByEnum(Features.UserTimetracking)

        permission = permissions.find(p => p.feature === projectTTFeature)?.hasAnyAccessLevel(['manage', 'read'])

        const userPermission = permissions.find(p => p.feature === userTTFeature)?.hasAnyAccessLevel(['manage', 'read'])

        return !(permission || userPermission)
      case 'settings-custom-fields':
        const projectManage = Feature.getFeatureStringByEnum(Features.ProjectManage)
        permission = permissions.find(p => p.feature === projectManage)?.hasAnyAccessLevel(['manage'])

        return !permission
      default:
        return false
    }
  }

  /**
   * Check permissions for settings categories item
   */
  private shouldHideCategories(isPageVisible: boolean, userPermissions: UserPermissions): boolean {
    const hasProjectTaskPermission = userPermissions?.permissions?.find(p =>
      ['task-manage-config', 'project-manage-config'].includes(p.feature)
    )

    return isPageVisible && !(userPermissions.isAdmin || hasProjectTaskPermission)
  }

  /**
   * Check permissions for settings categories item
   */
  private hasUserPlannerPermissions(userPermissions: UserPermissions): boolean {
    return userPermissions?.permissions?.some(p => p.feature === 'user-planning-data')
  }

  /**
   * Sets the default query params for the users query
   * @param {QueryParams} queryParams
   * @return {QueryParams}
   */
  private setDefaultQueryParams(queryParams: QueryParams = {}): QueryParams {
    return {
      ...queryParams,
      pageSize: queryParams.pageSize ?? 1000,
      page: queryParams.page ?? 1
    }
  }
}
