import { computed, effect, inject, Injectable, Injector, runInInjectionContext } from '@angular/core'
import { Router } from '@angular/router'
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  take
} from 'rxjs'
import { toObservable } from '@angular/core/rxjs-interop'
import { environment } from '@awork/environments/environment'
import { IDesktopAppUserSetting } from '@awork/_shared/models/user-setting.model'
import { TrackingService } from '@awork/_shared/services/tracking-service/tracking.service'
import { TrackingEvent } from '@awork/_shared/services/tracking-service/events'
import { BrowserService } from '@awork/_shared/services/browser-service/browser.service'
import { WorkspaceQuery } from '@awork/features/workspace/state/workspace.query'
import getLanguage from '@awork/_shared/functions/get-language'
import { SettingsQuery } from '@awork/framework/state/settings.query'
import { AppStore } from '@awork/core/state/app.store'
import { AccountQuery } from '@awork/_shared/state/account.query'
import { AccountService } from '../account-service/account.service'

@Injectable({ providedIn: 'root' })
export class ElectronService {
  appFocused$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true)

  private themeSubject = new Subject<'light' | 'dark'>()
  private menuWidthSubject = new Subject<number>()

  private userEmail = computed(() => this.accountQuery.query()().email)

  constructor(
    private router: Router,
    private trackingService: TrackingService,
    private browserService: BrowserService,
    private workspaceQuery: WorkspaceQuery,
    private settingsQuery: SettingsQuery,
    private accountQuery: AccountQuery,
    private appStore: AppStore,
    private injector: Injector
  ) {}

  get isElectron(): boolean {
    return navigator?.userAgent?.indexOf?.('Electron') >= 0 || !!window.electronAPI?.isElectron
  }

  get isAppActive(): boolean {
    return !document.hidden && this.appFocused$.value
  }

  get theme$(): Observable<'light' | 'dark'> {
    return this.themeSubject.asObservable()
  }

  get menuWidth$(): Observable<number> {
    return this.menuWidthSubject.asObservable()
  }

  /**
   * Initializes the electron service
   * @param {boolean} openLinksInDesktopApp
   */
  initialize(openLinksInDesktopApp: boolean): void {
    if (this.isElectron) {
      window.addEventListener('blur', () => this.appFocused$.next(false))
      window.addEventListener('focus', () => this.appFocused$.next(true))

      effect(
        () => {
          const isLoggedIn = this.appStore.getState().isLoggedIn()
          const email = isLoggedIn ? this.userEmail() : ''

          this.setAuthInfo(isLoggedIn, email)
        },
        { injector: this.injector }
      )

      const language = getLanguage().includes('de') ? 'de' : 'en'
      this.setLanguage(language)

      window.electronAPI?.onLogout?.(() => {
        runInInjectionContext(this.injector, () => {
          const accountService = inject(AccountService)
          accountService.logout()
        })
      })

      window.electronAPI?.onLanguageChange?.((newLanguage: 'en' | 'de') => {
        if (newLanguage !== language) {
          window.location.reload()
        }
      })

      toObservable(this.settingsQuery.queryLoading, { injector: this.injector })
        .pipe(
          filter(loading => !loading),
          debounceTime(1000),
          take(1),
          switchMap(() => toObservable(this.browserService.isDarkMode$, { injector: this.injector })),
          distinctUntilChanged(),
          map(isDarkMode => (isDarkMode ? 'dark' : 'light'))
        )
        .subscribe(theme => {
          this.setTheme(theme)
        })

      window.electronAPI?.onThemeChange?.((theme: 'dark' | 'light') => {
        this.themeSubject.next(theme)
      })

      toObservable(this.settingsQuery.queryLoading, { injector: this.injector })
        .pipe(
          filter(loading => !loading),
          debounceTime(1000),
          take(1),
          switchMap(() => toObservable(this.settingsQuery.queryFrameworkSettings(), { injector: this.injector })),
          map(frameworkViewSettings => (frameworkViewSettings.menuPinned ? frameworkViewSettings.menuWidth : 0)),
          distinctUntilChanged()
        )
        .subscribe(menuWidth => {
          this.setMainMenuWidth(menuWidth, true)
        })

      window.electronAPI?.onMenuWidthChange?.((width: number) => {
        this.menuWidthSubject.next(width)
      })

      this.workspaceQuery.selectCurrentWorkspace().subscribe(workspace => {
        this.setWorkspaceSubdomainName(workspace.getDefaultSubdomain().name)
      })

      this.trackingService.trackEvent(TrackingEvent.desktopAppUsed, {
        operating_system: this.browserService.getOS()
      })
    }

    if (document.visibilityState === 'hidden') {
      return
    }

    const isInNativeRedirectPage = window.location.pathname.includes('native')
    const isInResetPasswordPage = window.location.pathname.includes('reset-password')
    const shouldNavigateToDesktop = !isInNativeRedirectPage && !isInResetPasswordPage && environment === 'production'

    this.initNotifications()

    if (openLinksInDesktopApp && shouldNavigateToDesktop && !this.isElectron) {
      this.router.navigate(['/native'], {
        queryParams: { redirect: window.location.href },
        replaceUrl: true
      })
    }
  }

  /**
   * Sets the language in Electron
   * @param {'en'|'de'} language
   */
  setLanguage(language: 'en' | 'de'): void {
    if (!this.isElectron) {
      return
    }

    window.electronAPI?.setLanguage?.(language)
  }

  /**
   * Sets the theme in Electron
   * @param {'light'|'dark'} theme
   */
  setTheme(theme: 'light' | 'dark'): void {
    if (!this.isElectron) {
      return
    }

    window.electronAPI?.setTheme?.(theme)
  }

  /**
   * Sets the menu width in Electron
   * @param {number} width
   * @param {boolean} updateOtherTabs
   */
  setMainMenuWidth(width: number, updateOtherTabs = false): void {
    if (!this.isElectron) {
      return
    }

    window.electronAPI?.setMenuWidth?.(width, updateOtherTabs)
  }

  /**
   * Sets the workspace subdomain name in Electron
   * @param {boolean} authenticated
   * @param {string} userEmail
   */
  setAuthInfo(authenticated: boolean, userEmail?: string): void {
    if (!this.isElectron) {
      return
    }

    window.electronAPI?.setAuthInfo?.(authenticated, userEmail)
  }

  /**
   * Sets the workspace subdomain name in Electron
   * @param {string} name
   */
  setWorkspaceSubdomainName(name: string): void {
    if (!this.isElectron) {
      return
    }

    window.electronAPI?.setWorkspaceSubdomainName?.(name)
  }

  /**
   * Shows a native notification
   * @param {string} title
   * @param {string} body
   * @returns {Observable<void>}
   */
  showNotification(title?: string, body?: string): Observable<void> {
    const notificationTitle = title || 'Event'
    const notificationBody = body || 'There is a new event!'

    if (Notification.permission === 'granted') {
      const notification = new window.Notification(notificationTitle, { body: notificationBody, silent: true })

      return new Observable(observer => {
        notification.onclick = () => {
          this.focusWindow()

          observer.next()
        }
      })
    }

    return of()
  }

  /**
   * Opens the deep link in the electron app
   * @param {string} url
   */
  openDeepLink(url: string): void {
    // This opens the browser popup asking the user to proceed in the
    // desktop app. We pass the entire url so the electron app can proceed the
    // oauth flow there.
    const link = document.createElement('a')
    const deepLinkId = environment !== 'production' ? `awork-${environment}` : 'awork'

    link.href = `${deepLinkId}://url=${url}`
    document.body.appendChild(link)

    link.click()

    document.body.removeChild(link)
  }

  /**
   * Opens an external url in the default browser if the app is running in electron
   * @param {string} url
   */
  openExternalURL(url: string): void {
    if (!this.isElectron) {
      return
    }

    window.electronAPI?.openExternalURL?.(url)
  }

  /**
   * Checks if the desktop app is running for the first time
   * @param {IDesktopAppUserSetting} settings
   * @returns {boolean}
   */
  isFirstStart(settings: IDesktopAppUserSetting): boolean {
    return !(typeof settings?.desktopAppInstalled === 'boolean') && this.isElectron
  }

  /**
   * Initializes the notifications
   */
  private async initNotifications(): Promise<void> {
    if (this.isElectron && 'Notification' in window) {
      await Notification.requestPermission()
    }
  }

  /**
   * Focuses the electron window if the app is running in electron
   */
  private focusWindow(): void {
    if (!this.isElectron) {
      return
    }

    window.electronAPI?.showWindow?.()
  }
}
