import { HttpResponse } from '@angular/common/http'
import { UserSetting } from '@awork/_shared/models/user-setting.model'

/**
 * Safely returns the value of the response
 * @param {HttpResponse<UserSetting>} response
 * @returns {string | undefined}
 */
export function getSafeResponseValue(response: Partial<HttpResponse<UserSetting>>): string | undefined {
  return response?.body?.value
}

/**
 * Safely returns the status of the response body
 * @param {HttpResponse<UserSetting>} response
 * @returns {number | undefined}
 */
export function getSafeResponseStatus(response: Partial<HttpResponse<UserSetting>>): number | undefined {
  return response?.status
}

/**
 * Returns true if the value of the response body is the string 'true'
 * @param {HttpResponse<UserSetting>} response
 * @returns {boolean}
 */
export function isUserSettingTrue(response: HttpResponse<UserSetting>): boolean {
  return getSafeResponseValue(response) === 'true'
}
