import { EnvironmentInjector, Injectable } from '@angular/core'
import { BehaviorSubject, filter, forkJoin, map, Observable, ReplaySubject, shareReplay, switchMap, take } from 'rxjs'
import { UserSettingsService } from '@awork/_shared/services/user-settings-service/user-settings.service'
import { TrackingService } from '@awork/_shared/services/tracking-service/tracking.service'
import { TrackingEvent } from '@awork/_shared/services/tracking-service/events'
import { OnboardingTask, OnboardingTaskKey } from './types'
import { getFirstMatchingConfiguration } from './onboarding-checklist.configuration'
import { isAfter } from '@awork/_shared/functions/date-fns-wrappers'
import { UserQuery } from '@awork/features/user/state/user.query'
import { PermissionsQuery } from '@awork/features/workspace/state/permissions.query'
import { SubscriptionQuery } from '@awork/_shared/state/subscription.query'
import {
  onboardingChecklistClosedPermanentlyKey,
  onboardingTasksStateKey
} from '@awork/_shared/services/user-settings-service/keys'

@Injectable({ providedIn: 'root' })
export class OnboardingChecklistService {
  private isLoaded = new BehaviorSubject<boolean>(false)

  private onboardingTasks: BehaviorSubject<OnboardingTask[]> = new BehaviorSubject<OnboardingTask[]>(
    this.getInitialOnboardingTasks()
  )
  private isClosedPermanently: ReplaySubject<boolean> = new ReplaySubject<boolean>(1)

  public onboardingTasks$: Observable<OnboardingTask[]> = this.onboardingTasks.asObservable()
  public isClosedPermanently$: Observable<boolean> = this.isClosedPermanently.asObservable()

  private dependenciesLoaded = forkJoin([
    this.permissionsQuery.selectLoading().pipe(
      filter(loading => !loading),
      take(1)
    ),
    this.userQuery.selectCurrentUser().pipe(
      filter(user => !!user),
      take(1)
    ),
    this.subscriptionQuery.selectLoading().pipe(
      filter(loading => !loading),
      take(1)
    )
  ]).pipe(shareReplay(1))

  constructor(
    private userSettingService: UserSettingsService,
    private trackingService: TrackingService,
    private injector: EnvironmentInjector,
    private userQuery: UserQuery,
    private permissionsQuery: PermissionsQuery,
    private subscriptionQuery: SubscriptionQuery
  ) {
    this.getOnboardingTasks().subscribe(tasks => {
      this.onboardingTasks.next(tasks)
      this.isLoaded.next(true)
    })

    this.getIsClosedPermanently().subscribe(isClosed => {
      const userCreatedOn = this.userQuery.getCurrentUser()?.createdOn
      const isCreatedAfter = isAfter(userCreatedOn, new Date(2024, 7, 1))
      const isAdmin = this.permissionsQuery.getUserPermissions()?.isAdmin
      const isInTrial = this.subscriptionQuery.isInTrial()

      const isPermanentlyClosed = isCreatedAfter ? isClosed : isClosed || !isInTrial || !isAdmin
      return this.isClosedPermanently.next(isPermanentlyClosed)
    })
  }

  /**
   * Returns the default onboarding tasks for the current user
   * @returns {OnboardingTask[]}
   */
  private getInitialOnboardingTasks(): OnboardingTask[] {
    const initialConfiguration = getFirstMatchingConfiguration(this.injector)

    return !initialConfiguration ? [] : initialConfiguration.taskKeys.map(key => ({ key, isDone: false }))
  }

  /**
   * Marks a specific task with the given key as done
   * @param {OnboardingTaskKey} key The key of the task to mark as done
   */
  markOnboardingTaskAsDone(key: OnboardingTaskKey): void {
    if (!this.isLoaded.getValue()) {
      return
    }

    const currentState = this.onboardingTasks.getValue()
    const currentTask = currentState.find(t => t.key === key)

    if (!currentTask || currentTask.isDone) {
      return
    }

    const updatedTask = { ...currentTask, isDone: true }
    const updatedState = currentState.map(t => (t.key === key ? updatedTask : t))

    this.onboardingTasks.next(updatedState)
    this.saveOnboardingTasks()

    if (updatedState.every(task => task.isDone)) {
      this.trackingService.trackEvent(TrackingEvent.onboardingChecklistCompleted)
    }
  }

  /**
   * Closes the onboarding checklist permanently so it is not displayed again
   */
  closePermanently(): void {
    const isClosed = true

    this.isClosedPermanently.next(isClosed)
    this.userSettingService
      .setUserSetting(onboardingChecklistClosedPermanentlyKey, JSON.stringify(isClosed))
      .subscribe()
  }

  /**
   * Fetches the closed state of the onboarding checklist
   * @returns {Observable<boolean>}
   */
  private getIsClosedPermanently(): Observable<boolean> {
    const fetchSettings$ = this.userSettingService
      .getUserSetting(onboardingChecklistClosedPermanentlyKey)
      .pipe(
        map(isClosedPermanentlyResponse =>
          isClosedPermanentlyResponse.body?.value ? JSON.parse(isClosedPermanentlyResponse.body.value) : false
        )
      )

    return this.dependenciesLoaded.pipe(switchMap(() => fetchSettings$))
  }

  /**
   * Fetches the state of the onboarding tasks from the API
   * @returns {Observable<OnboardingTask[]>}
   */
  private getOnboardingTasks(): Observable<OnboardingTask[]> {
    const fetchSettings$ = this.userSettingService.getUserSetting(onboardingTasksStateKey).pipe(
      map(settings => {
        if (!settings?.body?.value) {
          return this.getInitialOnboardingTasks()
        }

        try {
          const tasks = JSON.parse(settings.body.value)
          return (Array.isArray(tasks) ? tasks : []) as OnboardingTask[]
        } catch (error) {
          return this.getInitialOnboardingTasks()
        }
      })
    )

    return this.dependenciesLoaded.pipe(switchMap(() => fetchSettings$))
  }

  /**
   * Saves the state of onboarding tasks in the user settings
   */
  private saveOnboardingTasks(): void {
    this.userSettingService
      .setUserSetting(onboardingTasksStateKey, JSON.stringify(this.onboardingTasks.getValue()))
      .pipe(take(1))
      .subscribe()
  }
}
