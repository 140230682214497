import { isSameMonth, isSameYear } from '../../../_shared/functions/date-fns-wrappers'

export interface IRetainerBudget {
  retainerId: string
  timeBudget: number
  trackedDuration: number
  startDate: Date | string
  endDate?: Date | string
}

export class RetainerBudget implements IRetainerBudget {
  retainerId: string
  timeBudget: number
  trackedDuration: number
  startDate: Date | string
  endDate?: Date | string

  constructor(init?: Partial<IRetainerBudget>) {
    Object.assign(this, init)
  }

  /**
   * Checks whether the retainerBudget startDate is on the same month as today.
   * @returns {boolean}
   */
  get isCurrentRetainerBudget(): boolean {
    const today = new Date()
    const startDate = new Date(this.startDate)
    return isSameYear(startDate, today) && isSameMonth(startDate, today)
  }
}
