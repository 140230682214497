import { getAworkContextualDate } from '@awork/_shared/functions/contextual-date'
import { addMinutes, addWeeks, isPast } from '@awork/_shared/functions/date-fns-wrappers'

type EmailContextualDateInput = { email: string; referenceDate: Date }

/**
 * Returns a short timeout for features
 * @param {Date} referenceDate
 * @returns {Date}
 */
export const getShortTimeout = (referenceDate: Date): Date => addMinutes(referenceDate, 2)

/**
 * Returns a long timeout for features
 * @param {Date} referenceDate
 * @returns {Date}
 */
export const getLongTimeout = (referenceDate: Date): Date => addWeeks(referenceDate, 2)

/**
 * Returns the default contextual date
 * @param {EmailContextualDateInput}
 * @returns {Date}
 */
export const getDefaultEmailContextualDate = ({ email, referenceDate }: EmailContextualDateInput): Date =>
  getAworkContextualDate({
    email,
    aworkDate: getShortTimeout(referenceDate),
    customerDate: getLongTimeout(referenceDate)
  })

/**
 * Returns true if the default contextual date is in the past
 * @param {EmailContextualDateInput}
 * @returns {boolean}
 */
export const isPastDefaultEmailContextualDate = ({ email, referenceDate }: EmailContextualDateInput): boolean =>
  isPast(getDefaultEmailContextualDate({ email, referenceDate }))
