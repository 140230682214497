import { Criterion } from './types'
import getLanguage from '../get-language'

/**
 * Returns true if the current user's language is german
 * @returns {boolean}
 */
export const isGermanLanguageCriterion: Criterion = () => getLanguage() === 'de'

/**
 * Returns true if the current user's language is english
 * @returns {boolean}
 */
export const isEnglishLanguageCriterion: Criterion = () => getLanguage() === 'en'
