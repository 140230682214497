import { Criteria } from '@awork/_shared/functions/criteria/async/types'

export enum OnboardingTaskKey {
  createTask = 'createTask',
  useCalendar = 'useCalendar',
  usePlanner = 'usePlanner',
  inviteUser = 'inviteUser',
  createProject = 'createProject',
  createPrivateProject = 'createPrivateProject',
  createComment = 'createComment',
  discoverProjectViews = 'discoverProjectViews',
  tryBusinessPlan = 'tryBusinessPlan',
  useDashboard = 'useDashboard',
  useProjects = 'useProjects',
  learnAboutTasks = 'learnAboutTasks',
  learnAboutProjectViews = 'learnAboutProjectViews',
  commentsAndCollaboration = 'commentsAndCollaboration',
  exploreDashboard = 'exploreDashboard'
}

export interface OnboardingChecklistConfiguration {
  id: string
  criteria: Criteria
  taskKeys: OnboardingTaskKey[]
}

export interface OnboardingTask {
  key: OnboardingTaskKey
  isDone: boolean
}
