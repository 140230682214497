export const onboardingTipsStateKey = 'onboarding-tips-state'
export const onboardingTasksStateKey = 'onboarding-tasks-state'
export const onboardingChecklistClosedPermanentlyKey = 'onboarding-checklist-closed-permanently'
export const atLeastOneProjectHasBeenCreatedKey = 'atLeastOneProjectHasBeenCreated'
export const isProjectPageBannerPermanentlyClosed = 'isProjectPageBannerPermanentlyClosed'
export const onboardingPopupPermanentlyClosedKey = 'onboardingPopupPermanentlyClosed'
export const onboardingVideoDashboardWidgetStatusKey = 'webinar_widget'
export const isOnboardingConnectProjectDashboardWidgetPermanentlyClosedKey = 'connectProjectWidgetPermanentlyClosed'
export const dashboardOnboardingVideoModalPermanentlyClosedKey = 'dashboardOnboardingVideoModalClosed'
export const isConnectGiftProjectRedeemedKey = 'connectGiftProjectRedeemed'
export const isConnectGiftProjectDeclinedKey = 'connectGiftProjectDeclined'

export type UserSettingKey =
  | typeof onboardingTipsStateKey
  | typeof onboardingTasksStateKey
  | typeof onboardingChecklistClosedPermanentlyKey
  | typeof atLeastOneProjectHasBeenCreatedKey
  | typeof isProjectPageBannerPermanentlyClosed
  | typeof onboardingPopupPermanentlyClosedKey
  | typeof onboardingVideoDashboardWidgetStatusKey
  | typeof isOnboardingConnectProjectDashboardWidgetPermanentlyClosedKey
  | typeof dashboardOnboardingVideoModalPermanentlyClosedKey
  | typeof isConnectGiftProjectRedeemedKey
  | typeof isConnectGiftProjectDeclinedKey
