import { SignalQuery } from '@awork/core/state/signal-store/signalQuery'
import { AppState, AppStore } from '@awork/core/state/app.store'
import { computed, Injectable, Signal } from '@angular/core'

@Injectable({ providedIn: 'root' })
export class AppQuery extends SignalQuery<AppState> {
  constructor(protected store: AppStore) {
    super(store)
  }

  /**
   * Gets the trace ids including the success and error calls' trace ids
   * @param {number} limit
   * @returns {Signal<{ traceIds: string[]; traceIdsError: string[] }>}
   */
  queryTraces(limit = 10): Signal<{ traceIds: string[]; traceIdsError: string[] }> {
    return computed(() => {
      const state = this.query()

      const { traceIds, traceIdsError } = state()

      return {
        traceIds: traceIds.slice(limit * -1),
        traceIdsError: traceIdsError.slice(limit * -1)
      }
    })
  }

  /**
   * Gets the trace ids including the success and error calls' trace ids
   * @param {number} limit
   * @returns {{ traceIds: string[]; traceIdsError: string[] }}
   */
  getTraces(limit = 10): { traceIds: string[]; traceIdsError: string[] } {
    return {
      traceIds: this.getValue().traceIds.slice(limit * -1),
      traceIdsError: this.getValue().traceIdsError.slice(limit * -1)
    }
  }

  /**
   * Gets the last version check date
   * @returns {Signal<Date>}
   */
  queryVersionCheck(): Signal<Date> {
    return computed(() => {
      const versionCheck = this.query('versionCheck')
      return new Date(versionCheck())
    })
  }

  /**
   * Gets the login flag
   * @returns {boolean}
   */
  getIsLoggedIn(): boolean {
    return this.getValue().isLoggedIn
  }

  /**
   * Selects the last sync date
   * @returns {Date}
   */
  selectLastSyncDate(): Date {
    return this.getValue().lastSyncDate
  }

  /**
   * Selects the registration id for push notifications
   * @returns {string}
   */
  selectPushNotificationRegistrationId(): string {
    return this.getValue().pushNotificationRegistrationId
  }

  /**
   * Gets the isInMSTeams flag
   * @returns {boolean}
   */
  getIsInMSTeams(): boolean {
    return this.getValue().isMSTeams
  }

  /**
   * Gets the debug flag
   * @returns {boolean}
   */
  getDebug(): boolean {
    return this.getValue().debug
  }

  /**
   * Gets the last time the cache were wiped
   * @returns {Date}
   */
  getCacheWiped(): Date {
    return this.getValue().cacheWiped
  }

  /**
   * Gets the last rendered route
   * @returns {string}
   */
  getLastRoute(): string {
    return this.getValue().lastRoute
  }

  /**
   * Gets the flag used to show to the user the API error caused by AdBlockers
   * @returns {boolean}
   */
  getAdBlockerErrorOccurred(): boolean {
    return this.getValue().adBlockerErrorOccurred
  }

  /**
   * Gets the flag used to determine if the app should open links in the desktop app
   * @returns {boolean}
   */
  getOpenLinksInDesktopApp(): boolean {
    return this.getValue().openLinksInDesktopApp
  }
}
