import { Time } from '@angular/common'
import { User } from '@awork/features/user/models/user.model'
import { Project } from '@awork/features/project/models/project.model'
import { Company } from '@awork/features/company/models/company.model'
import { Task } from '@awork/features/task/models/task.model'
import {
  applyDifferenceToTime,
  ExtendedTime,
  stringToTime,
  stripMilliseconds
} from '@awork/_shared/functions/time-operations'
import { addSeconds, differenceInSeconds } from '@awork/_shared/functions/date-fns-wrappers'
import { WorkType } from '@awork/features/task/models/work-type.model'
import { ProjectMember } from '@awork/features/project/models/project-member.model'

export enum ValidTimeTrackingEntityTypesList {
  project = 'project',
  user = 'user',
  task = 'task',
  company = 'company'
}

export type ValidTimeTrackingEntityTypes = keyof typeof ValidTimeTrackingEntityTypesList
export type TimelineFilters = 'week' | 'month' | 'year'
export type AggregationOptions = 'day' | 'week' | 'month'

export enum TimeTrackingStatuses {
  billed = 'billed',
  notBilled = 'notBilled',
  notBillable = 'notBillable'
}

export interface Break {
  startDate: Date
  endDate?: Date
  duration?: number
}

export interface TimeTrackingEntityAggregatedTimes {
  date: Date | string
  totalDuration: number
  typeOfWorkId?: string
  isBillable?: boolean
  isBilled?: boolean
  projectId?: string
  userId?: string
  companyId?: string
  secondaryDuration?: number
}

interface ITimeTracking {
  id: string
  note?: string
  timezone: string
  startDateUtc?: Date | string
  endDateUtc?: Date | string
  startTimeUtc?: Time | string
  endTimeUtc?: Time | string
  startDateLocal?: Date | string
  endDateLocal?: Date | string
  startTimeLocal?: Time | string
  endTimeLocal?: Time | string
  duration?: number
  isBillable?: boolean
  isBilled?: boolean
  typeOfWorkId: string
  typeOfWork: WorkType
  userId: string
  user: User
  taskId?: string
  task?: Task
  projectId?: string
  project?: Project
  companyId?: string
  company?: Company
  suggestionType?: string
  updatedByUser?: User
  createdByUser?: User
  createdOn?: Date
  updatedOn?: Date
  resourceVersion?: number
  modifiedOn?: number
  breakDuration?: number
  breaks?: Break[]
  isExternal?: boolean
}

export class TimeTracking implements ITimeTracking {
  id: string
  note?: string
  timezone: string
  startDateUtc?: Date | string
  endDateUtc?: Date | string
  startTimeUtc?: Time | string
  endTimeUtc?: Time | string
  startDateLocal?: Date | string
  endDateLocal?: Date | string
  startTimeLocal?: Time | string
  endTimeLocal?: Time | string
  duration?: number
  isBillable?: boolean
  isBilled?: boolean
  typeOfWorkId: string
  typeOfWork: WorkType
  userId: string
  user: User
  taskId?: string
  task?: Task
  projectId?: string
  project?: Project
  companyId?: string
  company?: Company
  suggestionType?: string
  updatedByUser?: User
  createdByUser?: User
  createdOn?: Date
  updatedOn?: Date
  resourceVersion?: number
  toBeDeleted?: boolean
  modifiedOn?: number
  breakDuration?: number
  breaks?: Break[]
  isExternal?: boolean

  constructor(spec: ITimeTracking) {
    Object.assign(this, spec)

    this.setDates()
    this.setTimes()
    this.mapProperties()

    if (!this.breakDuration && this.breaks) {
      this.setBreakDuration()
    }
  }

  /**
   * Returns true if the time tracking is active (running or paused)
   */
  get isActive(): boolean {
    return this.duration === 0 && !this.endTimeUtc
  }

  /**
   * Returns true if the time tracking is running
   */
  get isRunning(): boolean {
    return this.isActive && !this.isPaused
  }

  /**
   * Returns true if the time tracking is paused
   */
  get isPaused(): boolean {
    return this.isActive && !!this.currentBreak
  }

  /**
   * Sets the break duration based on the sum of the break durations
   */
  setBreakDuration(): void {
    this.breakDuration = this.breaks.reduce(
      (sum, timeBreak) => (timeBreak.duration ? sum + timeBreak.duration : sum),
      0
    )
  }

  /**
   * Returns the duration of the current break
   */
  get currentBreakDuration(): number {
    return differenceInSeconds(new Date(), this.currentBreak?.startDate) || 0
  }

  /**
   * Returns the total duration of the current and past breaks
   */
  get totalBreakDuration(): number {
    return (this.breakDuration || 0) + this.currentBreakDuration
  }

  /**
   * Returns the duration + total break duration
   */
  get totalDuration(): number {
    return this.duration + (this.totalBreakDuration || 0)
  }

  get currentBreak(): Break {
    return this.breaks?.find(b => !b.endDate)
  }

  get bestDescription(): string {
    if (this.task) {
      return this.task.name
    } else if (this.note && this.note.length > 1) {
      return this.note
    } else {
      return ''
    }
  }

  /**
   * Calculates the current duration of the running time tracking
   */
  get runningDuration(): number {
    if (this.isActive && this.startTimeLocal) {
      const runningStartTime =
        typeof this.startTimeLocal === 'string'
          ? (stringToTime(this.startTimeLocal, true) as ExtendedTime)
          : { ...this.startTimeLocal, seconds: 0 }
      const runningStartDateTime = new Date(this.startDateLocal as string)

      runningStartDateTime.setHours(runningStartTime.hours)
      runningStartDateTime.setMinutes(runningStartTime.minutes)
      runningStartDateTime.setSeconds(runningStartTime.seconds)
      return differenceInSeconds(new Date(), runningStartDateTime) - this.totalBreakDuration
    }

    return 0
  }

  get startDateTimeUtc(): Date {
    return this.getDateTime('start')
  }

  get startDateTimeLocal(): Date {
    return this.getDateTime('start', false)
  }

  get endDateTimeLocal(): Date {
    return this.getDateTime('end', false)
  }

  get status(): TimeTrackingStatuses {
    if (this.isBilled) {
      return TimeTrackingStatuses.billed
    } else if (this.isBillable) {
      return TimeTrackingStatuses.notBilled
    } else {
      return TimeTrackingStatuses.notBillable
    }
  }

  /**
   * Create an empty time tracking with default values
   * @returns {TimeTracking}
   */
  static createEmpty(timezone: string) {
    return new TimeTracking({
      duration: 3600, // 1 hour default
      timezone,
      startDateLocal: new Date(),
      endDateLocal: new Date(),
      isBillable: false,
      isBilled: false
    } as TimeTracking)
  }

  static getTimeTrackingsDuration(timeTrackings: TimeTracking[]): number {
    return timeTrackings.reduce((total, timeTracking) => total + timeTracking.duration, 0)
  }

  /**
   * Sets the dates as a Date object
   * and apply the timezone offset to the times
   * @param {boolean} forceUpdate
   */
  setDates(forceUpdate = false) {
    // UTC Dates
    if (this.startDateUtc && !(this.startDateUtc instanceof Date)) {
      this.startDateUtc = new Date(this.startDateUtc)
    }

    if (this.endDateUtc && !(this.endDateUtc instanceof Date)) {
      this.endDateUtc = new Date(this.endDateUtc)
    } else if ((!this.endDateUtc && this.duration && this.startDateTimeUtc) || forceUpdate) {
      this.endDateUtc = addSeconds(this.startDateTimeUtc, this.totalDuration)
    }

    // Local Dates
    if (this.startDateLocal && !(this.startDateLocal instanceof Date)) {
      this.startDateLocal = new Date(this.startDateLocal)
    }

    if (this.endDateLocal && !(this.endDateLocal instanceof Date)) {
      this.endDateLocal = new Date(this.endDateLocal)
    } else if ((!this.endDateLocal && this.duration && this.startDateTimeLocal) || forceUpdate) {
      this.endDateLocal = addSeconds(this.startDateTimeLocal, this.totalDuration)
    }

    if (this.createdOn) {
      this.createdOn = new Date(this.createdOn)
    }
  }

  /**
   * Sets the times for the time tracking
   * Strips the seconds (and milliseconds) from them
   * And calculate times based on duration (if possible)
   */
  setTimes() {
    if (this.startTimeUtc && typeof this.startTimeUtc === 'string') {
      this.startTimeUtc = stripMilliseconds(this.startTimeUtc as string)
    }

    if (this.startTimeLocal && typeof this.startTimeLocal === 'string') {
      this.startTimeLocal = stripMilliseconds(this.startTimeLocal as string)
    }

    if (this.endTimeUtc && typeof this.endTimeUtc === 'string') {
      this.endTimeUtc = stripMilliseconds(this.endTimeUtc as string)
    }

    if (this.endTimeLocal && typeof this.endTimeLocal === 'string') {
      this.endTimeLocal = stripMilliseconds(this.endTimeLocal as string)
    }

    if (this.duration) {
      if (!this.startTimeLocal && this.endTimeLocal) {
        this.startTimeLocal = applyDifferenceToTime(this.endTimeLocal, this.totalDuration * -1)
        this.startTimeUtc = applyDifferenceToTime(this.endTimeUtc, this.totalDuration * -1)
      } else if (!this.endTimeLocal && this.startTimeLocal) {
        this.endTimeLocal = applyDifferenceToTime(this.startTimeLocal, this.totalDuration)
        this.endTimeUtc = applyDifferenceToTime(this.startTimeUtc, this.totalDuration)
      }
    }
  }

  get projectMember(): ProjectMember {
    return this.project?.members?.find(({ userId }) => userId === this.userId)
  }

  /**
   * Gets the start/end date with the start/end time
   * @param {'start' | 'end'} period
   * @param {boolean} utc
   * @return {Date}
   */

  getDateTime(period: 'start' | 'end', utc = true): Date {
    let date: string | Date
    let time: string | Time

    if (period === 'start') {
      date = utc ? this.startDateUtc : this.startDateLocal
      time = utc ? this.startTimeUtc : this.startTimeLocal
    } else if (period === 'end') {
      date = utc ? this.endDateUtc : this.endDateLocal
      time = utc ? this.endTimeUtc : this.endTimeLocal
    }

    if (date && time) {
      const newDate = new Date(date as Date)
      let newTime: Time
      if (typeof time === 'string') {
        newTime = stringToTime(time as string)
      } else {
        newTime = time
      }

      newDate.setHours(newTime.hours)
      newDate.setMinutes(newTime.minutes)
      newDate.setSeconds(0)

      return newDate
    } else if (date) {
      const newDate = new Date(date as Date)

      newDate.setHours(0)
      newDate.setMinutes(0)
      newDate.setSeconds(0)

      return newDate
    }

    return null
  }

  /**
   * Maps the nested properties
   */
  mapProperties(): void {
    if (this.user) {
      this.user = new User(this.user, 'TimeTracking.mapProperties')
    }

    if (this.project) {
      this.project = Project.mapProject(this.project)
    }

    if (this.task) {
      this.task = Task.mapTask(this.task)
    }

    if (this.breaks) {
      this.breaks = this.breaks.map(timeBreak => ({
        ...timeBreak,
        startDate: new Date(timeBreak.startDate),
        endDate: timeBreak.endDate ? new Date(timeBreak.endDate) : undefined
      }))
    }
  }

  /**
   * Returns true if the time tracking doesn't have a project
   * @returns {boolean}
   */
  get isPrivate(): boolean {
    return !this.project && !this.task?.project
  }
}
