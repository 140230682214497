import { OnboardingChecklistConfiguration, OnboardingTaskKey } from './types'
import { InjectionToken, Injector, runInInjectionContext } from '@angular/core'
import { isConnectCriterion, isInTrialCriterion } from '@awork/_shared/functions/criteria/subscription.criteria'
import { isAdminCriterion } from '@awork/_shared/functions/criteria/permission.criteria'
import { isNotCriterion } from '@awork/_shared/functions/criteria/composition.criteria'
import { isEveryAsyncCriterionTrue } from '@awork/_shared/functions/criteria/async/composition'
import { concat, find, map, Observable, take } from 'rxjs'
import { isTrue } from '@awork/_shared/functions/criteria/async/helpers'

const configurations: OnboardingChecklistConfiguration[] = [
  {
    id: 'connect',
    criteria: [isAdminCriterion, isConnectCriterion],
    taskKeys: [
      OnboardingTaskKey.learnAboutTasks,
      OnboardingTaskKey.learnAboutProjectViews,
      OnboardingTaskKey.commentsAndCollaboration,
      OnboardingTaskKey.exploreDashboard
    ]
  },
  {
    id: 'default',
    criteria: [isAdminCriterion, isInTrialCriterion, isNotCriterion(isConnectCriterion)],
    taskKeys: [
      OnboardingTaskKey.createTask,
      OnboardingTaskKey.useCalendar,
      OnboardingTaskKey.inviteUser,
      OnboardingTaskKey.usePlanner,
      OnboardingTaskKey.createProject
    ]
  }
]

export const OnboardingChecklistConfigurationsToken = new InjectionToken('OnboardingChecklistConfigurations', {
  providedIn: 'root',
  factory: () => configurations
})

export const getFirstMatchingConfiguration = (injector: Injector) =>
  runInInjectionContext(
    injector,
    (): OnboardingChecklistConfiguration =>
      configurations.find(configuration => configuration.criteria.every(criterion => criterion()))
  )

export const getFirstMatchingConfiguration$ = (injector: Injector) =>
  runInInjectionContext(injector, (): Observable<OnboardingChecklistConfiguration> => {
    const sources = configurations.map(config =>
      isEveryAsyncCriterionTrue(config.criteria)().pipe(
        take(1),
        map(value => ({ config, value }))
      )
    )

    return concat(...sources).pipe(
      find(({ value }) => isTrue(value)),
      map(({ config }) => config)
    )
  })
