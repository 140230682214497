<article
  class="info-box"
  [style.width]="width"
  [class.info-box--center]="centerText"
  [ngClass]="color !== 'steel' ? 'aw-2-bg--' + color : 'aw-2-bg--ice'"
>
  <h3
    *ngIf="title"
    class="info-box__title aw-2-txt--h3"
    [ngClass]="color !== 'steel' ? 'aw-2-txt--' + color : 'aw-2-txt--night'"
  >
    {{title}}
  </h3>

  <p
    *ngIf="!htmlText && text"
    class="info-box__text aw-2-txt--bold"
    [class.aw-2-d--inline-block]="inlineText"
    [class.aw-2-mt--xs]="title"
    [ngClass]="'aw-2-txt--' + color"
  >
    {{ text }}
    <a *ngIf="clickableLinkText" class="link__text" (click)="linkClicked()"> {{ clickableLinkText }} </a>
  </p>

  <p
    *ngIf="htmlText && text"
    class="info-box__text aw-2-txt--bold"
    [class.aw-2-d--inline-block]="inlineText"
    [ngClass]="'aw-2-txt--' + color"
    [innerHtml]="text"
  ></p>

  <ng-content />
</article>
