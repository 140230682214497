import { filter, OperatorFunction } from 'rxjs'

export interface ActionEvent<Type, Payload = unknown> {
  type: Type
  payload?: Payload
}

export type ActionUnion<EventMap> = {
  [K in keyof EventMap]: ActionEvent<K, EventMap[K]>
}[keyof EventMap]

export function ofType<T extends keyof EventMap, EventMap>(
  eventType: T
): OperatorFunction<ActionUnion<EventMap>, Extract<ActionUnion<EventMap>, { type: T }>> {
  return filter((action): action is Extract<ActionUnion<EventMap>, { type: T }> => action?.type === eventType)
}
